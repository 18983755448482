<template>
  <div>
    <loading :active="!master.isRoleLoaded"></loading>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-small-hide">
        <img src="@/assets/img/slide.jpg" alt="Logo" class="slide-image" />
      </div>
      <div
        class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
      >
        <md-card class="card-rounded text-center p-2">
          <p class="md-body-2 m-0">Welcome To</p>
          <div>
            <img
              class="login-logo"
              src="@/assets/img/GlobeChek.png"
              alt="Globe Check Logo"
            />
          </div>
          <div class="d-flex justify-content-center">
            <div class="button-select-field">
              <md-field>
                <md-select v-model="userType">
                  <md-option
                    v-for="userType in master.roleList"
                    :value="userType.name"
                    :key="userType.name"
                  >
                    {{ userType.name }}
                  </md-option>
                </md-select>
              </md-field>
            </div>
          </div>
          <p class="md-caption py-3">
            To continue, select your user type and complete each of the
            following sections.
            <!-- Please complete each of the following sections. Once you have
            submitted your completed application, you will receive an email with
            your login credentioal and/or additional instruction within 48
            hours. -->
          </p>
          <div>
            <md-button class="btn-outline" @click="cancel()">Cancel</md-button>
            <md-button class="md-accent" @click="proceed()">Proceed</md-button>
          </div>
          <p class="md-caption pt-2">
            Need Help? <br />Contact us at <span v-html="gcEmail" />
          </p>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ["master"],
  data() {
    return {
      userType: "Doctor",
      gcEmail: '<a href="mailto:Info@GlobeChek.com">Info@GlobeChek.com</a>',
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    proceed() {
      this.$router.push(
        this.$route.path + "/" + String(this.userType).toLowerCase()
      );
    },
  },
};
</script>
<style>
.button-select-field {
  background: #3c6fe7;
  border-radius: 30px;
  color: #fff;
  padding: 0 15px;
  min-width: 260px;
}
.button-select-field .md-menu.md-select {
  align-items: center;
}
.button-select-field .md-icon.md-theme-default.md-icon-font,
.button-select-field .md-field {
  padding: 0;
  border: 0;
  margin: 0;
}
.button-select-field .md-field .md-input,
.button-select-field .md-field .md-textarea {
  height: 32px;
  padding: 0;
  -webkit-text-fill-color: #fff !important;
}
.button-select-field .md-icon.md-theme-default.md-icon-image svg {
  fill: #fff !important;
}
.button-select-field .md-field:after,
.button-select-field .md-field:before {
  display: none;
}
.md-button.md-theme-default.btn-outline {
  background: #fff !important;
  color: #444 !important;
  border: 1px solid #444;
}
.login-logo {
  width: 160px !important;
  margin: 5px auto 30px auto;
}
.md-caption {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 17px;
  font-family: "Segoe UI", Helvetica Noue, sans-serif;
}
</style>
